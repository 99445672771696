// import AppstoreIcon from "@/Icons/appstore.svg?react";
// import PlaystoreIcon from "@/Icons/playstore.svg?react";
import { memo, useContext } from "react";

import BB_Button from "@/Components/BB_Button";
import BB_ImageWrapper from "@/Components/BB_ImageWrapper";
import { DriverTourContext } from "@/ContextProviders/DriverTour";
import MagnifyingGlassIcon from "@/Icons/magnifyingGlass.svg?react";
import RegisterIcon from "@/Icons/register.svg?react";

const BB_Hero = () => {
	const { startSearchMiniTour } = useContext(DriverTourContext);

	return (
		<div className="bb_incentives_container relative">
			<div className="mx-auto mb-4 px-3 max-sm:pt-8 sm:px-0">
				<section>
					<div className="mx-auto flex">
						<div className="mr-auto place-self-center lg:col-span-7">
							<h1 className="mb-4 max-w-2xl text-3xl leading-none font-extrabold tracking-tight md:text-3xl dark:text-white">
								Verfügbarkeiten überwachen
							</h1>

							<p className="max-w-2xl font-light text-pretty text-gray-600 md:text-lg dark:text-gray-300">
								{"Kein "}
								<span className="font-bold">Release</span>
								{" mehr verpassen: Sobald dein gewünschtes Produkt (wieder) "}
								<span className="text-primary-500 font-bold">bestell.bar</span>
								{" ist, benachrichtigen wir dich sofort!"}
							</p>

							<div className={"mt-8 flex flex-wrap items-center gap-3"}>
								<BB_Button
									type="primary"
									title={"Gleich loslegen!"}
									icon={RegisterIcon}
									iconClassName="hidden sm:block h-5 w-5 mr-2 pointer-events-none"
									buttonClassName={"sm:w-fit sm:px-8"}
									onClick={route("register")}
								/>
								<span>oder</span>
								<BB_Button
									type="secondary"
									title={"Produkt suchen"}
									icon={MagnifyingGlassIcon}
									iconClassName="hidden sm:block h-5 w-5 mr-2 pointer-events-none"
									buttonClassName={"sm:w-fit sm:px-8"}
									onClick={startSearchMiniTour}
								/>
							</div>

							{/*
                            <div className="flex items-center gap-4 mt-6 lg:mt-8">
                                <a href="https://www.apple.com/de/app-store" target="_blank" rel="noopener noreferrer" >
                                    <AppstoreIcon className="h-10 sm:h-11 lg:h-11" />
                                </a>

                                <a href="https://play.google.com/store/games" target="_blank" rel="noopener noreferrer" >
                                    <PlaystoreIcon className="h-10 sm:h-11 lg:h-11" />
                                </a>
                            </div>
                            */}
						</div>
						<div className="ms-auto hidden lg:flex lg:pr-7">
							<BB_ImageWrapper
								bb_image={{
									url: "https://images.bestell.bar/images/banner/products-banner-v2.webp?class=6",
									width: 287,
									height: 287,
									trimmed: 0
								}}
								className="h-auto max-h-72 w-auto select-none"
								alt="Produktsammlungs Beispiel Banner"
								isLazy={false}
							/>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default memo(BB_Hero);
